import React, { useState } from 'react';
//import Button from '@material-ui/core/Button';
import {
    Button,
    TextField,
    List, ListItem, ListItemText, Avatar, ListItemAvatar,
    DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Typography, Grid, Fab
} from '@material-ui/core';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, Redirect
} from "react-router-dom";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

//ICONOS

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


// CONSTANTES

const PassObj = {};
PassObj.icon = 'https://comunidad.olinca.edu.mx/com/shared/img/menu/BKicon_2.png';

const topfill = {
    height: "100%",
    paddingTop: 50,
    backgroundColor: "#fff"
  };


//COMPONENTES

class CambioPass extends React.Component {

    constructor() {
        super();
        this.state = {
            correos: [],
            id: [],
            llave: []
        };
        this.ponestado = this.ponestado.bind(this);
    }

    ponestado(data, hashes, ids) {
        //alert("1 Desde SetState " + this.state.correos);
        this.setState({ correos: data, id: ids, llave: hashes });
        //alert("2 Desde SetState " + this.state.correos[0]);
    }

    render() {

        return (
            <Router >

                <Switch>
                    <Route path="/selectemail">
                        <SelectEmail CPstate={this.state} ></SelectEmail>
                    </Route>
                    <Route path="/numfamilia">
                        <ConNumFamilia stateHandler={this.ponestado} saludo="hola"></ConNumFamilia>
                    </Route>
                    <Route path="/email">
                        <ConEmail></ConEmail>
                    </Route>
                    <Route path="/confirmacion">
                        <Confirmacion></Confirmacion>
                    </Route>
                    <Route path="/">
                        <MenuInicial></MenuInicial>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

class Confirmacion extends React.Component {

    render() {
        return (
            <div>

                <Grid container spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center" style={{ minHeight: '75vh' }}>
                    <Grid item xs={6}>

                        <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
                            <div>
                                <img src={PassObj.icon} alt="ICONO"></img>
                            </div>
                            <div>
                                Solicitud Confirmada
                            </div>
                        </Typography>
                        <Typography variant="body1" align="center" color="textSecondary" paragraph>
                            Su solicitud de cambio de password ha sido recibida y se le ha enviado un correo electrónico para confirmar la solicitud. Por favor revise su buzon de correo
                            y abra la liga contenida en el mensaje de confirmación.
                        </Typography>

                    </Grid>
                </Grid>
            </div>

        );
    }

}

class BotonInicial extends React.Component {
    render() {
        return (
            <div>
                <Link to={this.props.objetivo}>
                    <Button variant="contained" color="primary" >
                        {this.props.leyenda}
                    </Button>
                </Link>
            </div>
        );
    }

}

class MenuInicial extends React.Component {
    render() {
        return (
            <Grid container spacing={0}
                direction="column"
                alignItems="center"
                justify="center" style={{ minHeight: '75vh' }}>
                <Grid item xs={8}>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
                        <div>
                            <img src={PassObj.icon} alt="ICONO"></img>
                        </div>
                        <div>
                            Recuperación de contraseña
                        </div>
                    </Typography>
                    <Typography variant="body1" align="center" color="textSecondary" paragraph>
                        Estimado padre de familia, el presente formulario le permite recuperar su contraseña de acceso a la APP Olinca y a la comunidad.
                        Por favor indique si cuenta o no con su número de familia.
                    </Typography>

                </Grid>
                <Grid item xs={8} alignItems="center">
                    <Grid container spacing={2} justify="space-evenly" direction="row" alignItems="center">
                        <Grid item>
                            <BotonInicial leyenda="Tengo mi número de familia" objetivo="numfamilia"></BotonInicial>
                        </Grid>

                        <Grid item>
                            <BotonInicial leyenda="No tengo mi número de familia" objetivo="email"></BotonInicial>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        );
    }
}

class SelectEmail extends React.Component {

    constructor() {
        super();
        this.state = {
            estado: "principal"
        };
    }

    seleccionarEmail(indice) {
        var tosend_id = this.props.CPstate.id[indice];
        var tosend_hash = this.props.CPstate.llave[indice];
        //alert("mandando: " + tosend_id + " " + tosend_hash);


        fetch('https://comunidad.olinca.edu.mx/app/pass/aselemail.php?i=' + tosend_id + "&t=" + tosend_hash)
            .then(res => res.json())
            .then((data) => {
                //alert(data.exito);
                //alert(data.error);
                //console.log('i=' + tosend_id + "&t=" + tosend_hash)
                if (data.exito === 1) {
                    //hacer los datos globales
                    this.setState({ estado: "confirmar" });
                } else if (data.exito === 0) {
                    //alert(data.descripcionerror);
                } else {
                    //alert("Error de comunicación");
                }

            })
            .catch(console.log)


    }

    render() {

        var acceso; // = this.state.acceso;
        //if (typeof(this.props.CPstate.correos) === 'undefined'){
        if (this.props.CPstate.correos.length === 0) {
            acceso = false;
        } else {
            acceso = true;
        }



        if (acceso === false) {
            return (
                <Redirect to="/"></Redirect>
            );
        }

        if (this.state.estado === "confirmar") {
            return (
                <Redirect to="/confirmacion"></Redirect>
            );
        }else if(this.state.estado === "back"){
            return (
                <Redirect to="/numfamilia"></Redirect>
            );
        } else if (this.state.estado === "principal") {
            return (
                <div>
                    <Grid container spacing={2}
                        direction="column"
                        alignItems="center"
                        justify="center" style={{ minHeight: '90vh' }}>
                        <Grid item xs={8}>
                            <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                                <div>
                                    <img src={PassObj.icon} alt="ICONO"></img>
                                </div>
                                <div>
                                    Seleccione su email
                        </div>
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary" paragraph>
                                Por favor seleccione su dirección de correo electrónico, le será enviado un mensaje con instrucciones para recuperar su contraseña.
                    </Typography>

                        </Grid>
                        {/*<ul>
                    {this.props.CPstate.correos.map(item => (<li>{item}</li>))}
                </ul>*/}

                        <div>
                            <List>

                                {this.props.CPstate.correos.map((item, i) => (
                                    <ListItem button onClick={() => { this.seleccionarEmail(i); }} >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <ContactMailIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={item} />
                                    </ListItem>))}
                            </List>
                        </div >




                    </Grid>
                    <Fab color="primary" aria-label="add">
                <ArrowBackIcon  onClick={() => { this.setState({estado: "back"}) }}/>
                </Fab>
                </div>
            );
        }
    }
}


class ConNumFamilia extends React.Component {

    constructor() {
        super();
        /*estado:    principal, alerta, seleccionarfamilia,back */

        this.state = {
            estado: "principal",
            alertaopen: false,
            alertamensaje: ""
        };
        this.cerrador_alerta = this.cerrador_alerta.bind(this);
    }


    cerrador_alerta() {
        this.setState({ alertaopen: false });
    }

    render() {
        if (this.state.estado === "seleccionarfamilia") {
            return (
                <Redirect to="/selectemail"></Redirect>
            );
        }else if(this.state.estado === "back"){
            return (
                <Redirect to="/"></Redirect>
            );
        }

        return (
            <div>
                <Grid container spacing={2}
                    direction="column"
                    alignItems="center"
                    justify="flex-start" style={{ minHeight: '90vh' }}>
                    <Grid item xs={12} >
                    <div style={topfill}></div>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                            <div>
                                <img src={PassObj.icon} alt="ICONO"></img>
                            </div>
                            <div>
                                Recuperación por número de familia.
                        </div>
                        </Typography>
                        <Typography variant="body1" align="center" color="textSecondary" paragraph>
                            Por favor ingrese su número de familia
                    </Typography>
                    </Grid>

                    <Grid item xs={8}>
                        <form noValidate autoComplete="off">
                            <Grid container spacing={1}
                                direction="column"
                                alignItems="center"
                                justify="center">
                                <Grid item xs={12}>
                                    <TextField id="numfam" label="Número de familia" variant="outlined" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" endIcon={<ArrowForwardIcon />}

                                        onClick={() => {
                                            //revisar el webservice
                                            var valor = document.getElementById("numfam").value;
                                            if (valor.trim() === "") {
                                                //alert("Se requiere un número de familia");
                                                this.setState({ alertaopen: true, alertamensaje: "Se requiere un número de familia" })
                                                return;
                                            }

                                            fetch('https://comunidad.olinca.edu.mx/app/pass/afamilia.php?familia=' + valor)
                                                .then(res => res.json())
                                                .then((data) => {
                                                    //this.setState({ contacts: data })
                                                    //alert(data.exito);

                                                    if (data.exito === 1) {
                                                        //hacer los datos globales
                                                        this.props.stateHandler(data.correos, data.hashes, data.ok);
                                                        //alert("TODO saltar")
                                                        this.setState({ estado: "seleccionarfamilia" })
                                                        //history.push("/selectemail");
                                                    } else if (data.exito === 0) {
                                                        //alert(data.descripcionerror);
                                                        this.setState({ alertaopen: true, alertamensaje: data.descripcionerror })

                                                    } else {
                                                        //alert("Error de comunicación");
                                                        this.setState({ alertaopen: true, alertamensaje: "Error de comunicación" })

                                                    }
                                                })
                                                .catch((error) => {
                                                    console.error('Error:', error);
                                                    this.setState({ alerta_open: true, alerta_mensaje: "Error de comunicación, por favor intente más tarde" });
                                                })
                                            //.catch(console.log)
                                            //history.push("/");
                                        }}
                                    >
                                        Continuar
                     </Button>
                                </Grid></Grid>
                        </form>
                    </Grid>
                    
                </Grid>

                <MiAlerta isOpen={this.state.alertaopen} mensaje={this.state.alertamensaje} cerrador={this.cerrador_alerta}></MiAlerta>
                
                <Fab color="primary" aria-label="add">
                <ArrowBackIcon  onClick={() => { this.setState({estado: "back"}) }}/>
                </Fab>

            </div>
        );

    }

}
/*
const ConNumFamilia = (props) => {
    const [aopen ,useAopen] = useState(0);
    let history = useHistory();
    return (
        <div>
            <div>Número de familia {props.saludo} : {aopen}</div>
            <div>
                <form noValidate autoComplete="off">
                    <TextField id="numfam" label="Número de familia" variant="outlined" />
                    <Button variant="contained" color="primary" endIcon={<ArrowForwardIcon />}
                        onClick={() => {
                            //revisar el webservice
                            var valor = document.getElementById("numfam").value;
                            if (valor.trim() === "") {
                                alert("Se requiere un número de familia");
                                return;
                            }

                            fetch('https://comunidad.olinca.edu.mx/app/pass/afamilia.php?familia=' + valor)
                                .then(res => res.json())
                                .then((data) => {
                                    //this.setState({ contacts: data })
                                    //alert(data.exito);
                                    
                                    if (data.exito === 1) {
                                        //hacer los datos globales
                                        props.stateHandler(data.correos, data.hashes, data.ok);
                                        history.push("/selectemail");
                                    } else if (data.exito === 0) {
                                        alert(data.descripcionerror);
                                        useAopen({aopen: 1});
                                        () => useAopen(count + 1);
                                    } else {
                                        alert("Error de comunicación");
                                    }
                                })
                                .catch(console.log)
                            //history.push("/");
                        }}
                    >
                        Continuar
                     </Button>
                </form>
            </div>
            <MiAlerta isOpen={aopen} mensaje="hola" cerrador="TODO"></MiAlerta>
        </div>
    );
}
*/

class ConEmail extends React.Component {

    constructor(props) {
        super(props);
        this.alertclose = this.alertclose.bind(this);
    }


    state = {
        email: '',
        enviado: false,
        alerta_open: false,
        alerta_mensaje: ""
    }

    handleChange = (event) => {
        const email = event.target.value;
        this.setState({ email });
    }

    alertclose() {
        this.setState({ alerta_open: false, alerta_mensaje: "" });
    }

    handleSubmit = () => {
        // your submit logic
        //alert("enviando " + this.state.email);
        fetch('https://comunidad.olinca.edu.mx/app/pass/aemail.php?email=' + this.state.email)
            .then(res => res.json())
            .then((data) => {
                //this.setState({ contacts: data })
                //alert(data.exito);


                if (data.exito === 1) {
                    this.setState({ enviado: true });
                    //this.forceUpdate();
                } else if (data.exito === 0) {
                    //alert(data.error);
                    this.setState({ alerta_open: true, alerta_mensaje: data.error });
                } else {
                    //alert("Error de comunicación");
                    this.setState({ alerta_open: true, alerta_mensaje: "Error de comunicación, por favor intente más tarde" });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({ alerta_open: true, alerta_mensaje: "Error de comunicación, por favor intente más tarde" });
            })
        //.catch(console.log)


    }

    render() {
        const { email } = this.state;

        if (this.state.enviado === true) {
            return (
                <Redirect to="/confirmacion"></Redirect>
            );
        }else if(this.state.estado === "back"){
            return (
                <Redirect to="/"></Redirect>
            );        

        } else return (
            <div>


                <Grid container spacing={2}
                    direction="column"
                    alignItems="center"
                    justify="flex-start" style={{ minHeight: '90vh' }}>
                    <Grid item xs={12} >
                    <div style={topfill}></div>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                            <div>
                                <img src={PassObj.icon} alt="ICONO"></img>
                            </div>
                            <div>
                                Recuperación por email.
                            </div>
                        </Typography>
                        <Typography variant="body1" align="center" color="textSecondary" paragraph>
                            Por favor ingrese el email registrado en el colegio. Solo son válidos los correos de los padres de familia.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>

                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={errors => console.log(errors)}
                        >
                            <Grid container spacing={1}
                                direction="column"
                                alignItems="center"
                                justify="center">
                                <Grid item xs={12}>
                                    <TextValidator
                                        variant="outlined"
                                        label="Email"
                                        onChange={this.handleChange}
                                        name="email"
                                        value={email}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['Campo requerido', 'Dirección inválida']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary" endIcon={<ArrowForwardIcon />}>Continuar</Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Grid>
                <MiAlerta isOpen={this.state.alerta_open} mensaje={this.state.alerta_mensaje} cerrador={this.alertclose}></MiAlerta>
                <Fab color="primary" aria-label="add">
                <ArrowBackIcon  onClick={() => { this.setState({estado: "back"}) }}/>
                </Fab>
            </div>
        );
    }
}



class MiAlerta extends React.Component {

    state = {
        open: false,
        mensaje: ""
    };

    /*handleClose = () => {
        this.setState({ open: false });
    };

    setOpen(texto) {
        this.setState({ open: true, mensaje: texto });
    }*/

    render() {
        return (

            <Dialog
                open={this.props.isOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Atención"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.mensaje}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.cerrador} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


export default CambioPass;